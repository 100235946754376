<template>
  <div class="loading-skeleton">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="card-header p-0">
              <label>headerheaderheaderheaderheaderheaderheader</label>
            </div>
            <div class="row d-flex justify-content-center mb-4">
              <div class="col-sm-10">
                <h5 class="card-title mt-10 mb-10">Card title</h5>
                <h5 class="card-title mb-10">Card title</h5>
                <h5 class="card-title mb-10">Card title</h5>
                <h5 class="card-title mb-10">Card title</h5>
                <h5 class="card-title mb-10">Card title</h5>
                <p class="card-text mb-10">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>

            <hr class="mb-10" style="color: LightGrey" />
            <div class="row d-flex justify-content-center mb-4">
              <div class="col-sm-10">
                <a href="#" class="btn btn-sm btn-primary me-2">Go somewhere</a>
                <a href="#" class="btn btn-sm btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/sass/loader.scss";
</style>
